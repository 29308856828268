import TreeUtils from './TreeUtils';
import Cons from './Cons';

class ReCalUtil {

    constructor(rowConfArray, colConfArray) {
        this.props = {
            rowConfArray,
            colConfArray,
            columnCodes : colConfArray.map(q => q.code)
        }
    }

    changeData(newValue, colIndex, rowIndex, oldData) {
        let colConf = this.props.colConfArray[colIndex];
        let key = colConf.key + " " + this.props.rowConfArray[rowIndex].key;
        let newData = Object.assign({}, oldData);
        if (colConf.cType === Cons.C_TYPE[0]) {
            newData[key] = newValue;
            return newData;
        }

        if (!newValue) newValue = 0;

        newData[key] = parseInt(newValue);

        // update relative data
        this.updateByFunc(colIndex, rowIndex, newData, oldData);

        // update percent
        this.recalculatePercent(newData, this.props.rowConfArray[rowIndex]);
        return newData;
    }

    calculateCheck(data) {
        for (let rowIndex = 0; rowIndex < this.props.rowConfArray.length; rowIndex++) {
            let colConfs = this.props.colConfArray.filter(q => !!q.checkFunc);
            for (const colConf of colConfs) {
                this.calculateFunc(colConf, rowIndex, data, true)
            }
        }
    }

    recalculatePercent(data, rowConf) {
        let percentColConf = this.props.colConfArray.filter(q => q.func && q.func[0] == 'p');
        let rowConfs = TreeUtils.findAllParent(this.props.rowConfArray, rowConf.code);
        rowConfs.push(rowConf);
        for (let rowCo of rowConfs) {
            const rowIndex = this.props.rowConfArray.indexOf(rowCo);
            for (let colConf of percentColConf) {
                this.calculateFunc(colConf, rowIndex, data);
            }
        }
    }

    updateByFunc(colIndex, rowIndex, newData, oldData) {
        let colCode = this.props.colConfArray[colIndex].code;
        let tempColIndex = 0;
        let key = this.props.colConfArray[colIndex].key + " " + this.props.rowConfArray[rowIndex].key;
        
        let preValue = oldData[key];
        if (!preValue) preValue = 0;

        this.updateRowParentValue(this.props.colConfArray[colIndex].key, rowIndex, newData, oldData);
        for (let col of this.props.colConfArray) {
            let func = col.func;
            if (func && func[0] !== 'p' && func.includes("{" + colCode + "}")) {
                this.calculateFunc(col, rowIndex, newData);
                this.updateByFunc(tempColIndex, rowIndex, newData, oldData);
            }
            tempColIndex++;
        }
    }

    calculateFunc(colConfig, rowIndex, data, forCheck = false) {
        let func = "" + colConfig.func;
        if (forCheck) {
            func = colConfig.checkFunc;
        }
        const isPercent = func[0] === 'p';
        if (isPercent) {
            func=func.replace("p", "");
        }
        let regex = /\{[0-9]{1,2}(\.[0-9]{1,2})*\}/g;
        let params = func.match(regex);
        const rowConfig = this.props.rowConfArray[rowIndex];
        let key = colConfig.key + " " + rowConfig.key;
        if (forCheck) {
            key = "c_" + key;
        }

        let paramsValue = {}
        for (let para of params) {
            let colCode = para.replace("{", "").replace("}", "");
            let colConf = TreeUtils.findNodeByCode(this.props.colConfArray, colCode);
            paramsValue[para] = data[colConf.key + " " + rowConfig.key];
            if (!paramsValue[para]) paramsValue[para] = 0;
            func = func.replace(para, paramsValue[para].toString());
        }
        data[key] = eval(func);
    }

    updateRowParentValue(colKey, rowIndex, data, oldData) {
        const rolConf = this.props.rowConfArray[rowIndex];
        const parentRowCode = TreeUtils.findParentCode(rolConf.code);
        if (!parentRowCode) return;

        let parentRow = this.props.rowConfArray.filter(q => q.code === parentRowCode);
        if (!parentRow || parentRow.length == 0) return;
        parentRow = parentRow[0];
        let parentRowIndex = this.props.rowConfArray.indexOf(parentRow);

        let value = 0;
        let childRows = TreeUtils.findAllChild(this.props.rowConfArray, parentRow.code);
        for (const childRow of childRows) {
            value += !data[colKey + " " + childRow.key] ? 0 : data[colKey + " " + childRow.key];
        }
        data[colKey + " " + parentRow.key] = value;

        this.updateRowParentValue(colKey, parentRowIndex, data, oldData);
    }

}

export default ReCalUtil;
