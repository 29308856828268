import ExcelJS from "exceljs";
import TreeUtils from "./TreeUtils";
import Cons from "./Cons";
import ImportDanhSach from "./ImportDanhSach";

const colors = ["F1F1F1", "FFFFFF"];

const importFileExcelBuilder = {

    /**
     * Tạo file import
     * @param {BaoCaoNgayModel} baoCaoNgayModel Data and config
     */
    build(phongBans, preImportData) {
        this.Headers = ImportDanhSach.dataMap.map(q => q.name);
        const workbook = new ExcelJS.Workbook();

        workbook.creator = 'Phan Van Binh';
        workbook.created = new Date();
        workbook.modified = new Date();

        const sheet = workbook.addWorksheet('FileImport');

        let danhMucName = 'Danh Mục'
        const sheet2 = workbook.addWorksheet(danhMucName);
        this.makeHeader(sheet);

        this.setValidateList(sheet, sheet2);

        const importData = this.makeNewData(preImportData);
        this.makeBody(sheet, importData);
        return workbook;
    },

    makeNewData(preImportData) {
        const importData = preImportData.filter(q => q.status !== Cons.HIEN_HUU[2] && q.status !== Cons.HIEN_HUU[4]);
        for (const data of importData) {
            if (data.status !== Cons.HIEN_HUU[1] || data.status !== Cons.HIEN_HUU[3]) {
                data.status = Cons.HIEN_HUU[0];
            }
        }
        return importData;
    },

    makeBody(sheet, preImportData) {
        let index = 1;
        for (let nv of preImportData) {
            nv.stt = index;
            this.makeRowData(sheet, nv, index + 1);
            index++;
        }
    },

    makeRowData(sheet, nv, rowIndex) {
        let colName = 'A';
        for (let col of ImportDanhSach.dataMap) {
            const cellName = colName + rowIndex;
            const cell = sheet.getCell(cellName);
            cell.value = nv[col.feild];
            this.setStyle(cell, false);
            colName = TreeUtils.getShiftColLabel(colName, 1);
        }
    },

    makeHeader(sheet) {
        let columnName = 'A';
        for (let col of this.Headers) {
            let cellName = columnName + 1;
            const cell = sheet.getCell(cellName);
            cell.value = col;
            columnName = TreeUtils.getShiftColLabel(columnName, 1);
            this.setStyle(cell, true);
        }
    },

    setValidateList(sheet, enumSheet) {
        let listCol = ImportDanhSach.dataMap.filter(q => q.enum);
        const refers = [];
        let startCol = 'A';
        for (let col of listCol) {
            const refer = this.makeReferList(enumSheet, ImportDanhSach.configs[col.enum], startCol, col.name);
            startCol = TreeUtils.getShiftColLabel(startCol, 1);
            this.setValidateCol(sheet, col.name, 2, ["='" + enumSheet.name + "'!" + refer], true);
        }
    },

    makeReferList(sheet, referList, startCol, refName) {
        let cell = sheet.getCell(startCol + "1");
        cell.value = refName;
        this.setStyle(cell, true);
        let cellIndex = 2;
        for (const name of referList) {
            cell = sheet.getCell(startCol + cellIndex);
            cell.value = name;
            this.setStyle(cell, false);
            cellIndex++;
        }
        return "$" + startCol + "$2:" + "$" + startCol + "$" + (cellIndex - 1);
    },

    setStyle(cell, isHeader) {
        let color = isHeader ? colors[0] : colors[1];
        cell.border = {
            top: {style:'thin'},
            left: {style:'thin'},
            bottom: {style:'thin'},
            right: {style:'thin'}
        };
        cell.fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:color},
        };
    },

    setValidateCol(sheet, colName, starIndex, referList, isString = false) {
        let startCol = this.Headers.indexOf(colName);
        startCol = TreeUtils.getShiftColLabel('A', startCol);
        let formulae = !isString ? ['"' + referList.join(',') + '"'] : referList;
        sheet.dataValidations.add(startCol + starIndex + ':' + startCol + '9999', {
            type: 'list',
            allowBlank: false,
            formulae,
            showErrorMessage: true,
            errorStyle: 'error',
            error: 'The value Valid',
        });
    },
}

export default importFileExcelBuilder;