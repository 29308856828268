import React, { Component } from 'react';
import BaoCaoNgayService from '../services/BaoCaoNgayService';
import HeaderTable from '../compoments/bcTable/Header';
import BodyBaoCao from '../compoments/bcTable/BodyBaoCao';
import TreeUtils from '../utils/TreeUtils';
import dayjs from 'dayjs';
import UserService from '../services/UserService';
import excelBuilder from '../utils/excelBuilder';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import DescriptionIcon from '@mui/icons-material/Description';
import { green } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import "../styles/ThacoTable.css"
import { Box } from '@mui/material';

const userService = new UserService();
const baoCaoNgayService = new BaoCaoNgayService();

const ColorButton = styled(Button)(({ theme }) => ({
    
    fontFamily: [
        'Arial', 'Helvetica', 'sans-serif'
      ].join(','),
    color: theme.palette.getContrastText(green[700]),
    backgroundColor: green[700],
    '&:hover': {
      backgroundColor: green[900],
    },
  }));
class ThongKeDiemDanhPage extends Component {

    constructor(props) {
        super(props);
        this.body = React.createRef();
    }

    state = { 
        baoCaoNgay: null,
        colArray: null,
        rowArray: null,
        selectedDate: dayjs(new Date).format('YYYY-MM-DD'),
        user: null,
        
        visbleImport: false,
    }

    onChangeSelectedDate = (ev) => {
        let selectedDate = ev.target.value;
        this.changeDate(selectedDate);
        this.setState({selectedDate});
    }

    changeDate = (selectedDate) => {
        userService.getCurrent().then((user) => {
            this.setState({user});
            baoCaoNgayService.getByDate(selectedDate).then((bc) => {
                if (!bc.data) bc.data = {};
                const colArray = TreeUtils.getMaxLevelArray(bc.colConfig);
                this.columnCodes = colArray.map(q => q.code);
                const rowArray = TreeUtils.flatTree(bc.rowConfig);
                TreeUtils.loadForAccount(rowArray, bc.rowConfig.forAccounts);
                this.setState({rowArray, baoCaoNgay: bc, colArray});
            });
        });
    }

    componentDidMount() {
        this.changeDate(this.state.selectedDate);
    }

    download = (exConf = {}) => {
        let bcNgay = Object.assign({}, this.state.baoCaoNgay);
        excelBuilder.init(this.state.colArray, this.state.rowArray)
        const exportOption = {userName: this.state.user.name};
        Object.assign(exportOption, exConf);
        const excel = excelBuilder.build(bcNgay, exportOption);
        excel.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer]);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = "bcNgay" + bcNgay.createdDate + ".xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
        })
    }

    saveData = () => {
        const updateDataWrap = {};
        updateDataWrap.id = this.state.baoCaoNgay._id;
        updateDataWrap.data = this.body.current.updateData;

        baoCaoNgayService.update(updateDataWrap).then((bc) => {
            alert("Đã lưu");
        }).catch((ex) => {
            alert("Không thể lưu");
        })
    }

    render() {
        // let userName = this.state.user ? this.state.user.name : "";
        return (
            <Box>
                <Box sx={{ width: '100%', typography: 'body1', padding:'10px',fontSize:'20px',textAlign: 'center', fontFamily:'ui-rounded', fontWeight:'700' }}>BÁO CÁO SỐ LIỆU NHÂN SỰ HẰNG NGÀY</Box>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                    >
                    <div>
                        <span>Ngày nhập: </span>
                        <input
                            type="date"
                            value={this.state.selectedDate}
                            onChange={this.onChangeSelectedDate} />
                    </div>
                    {/* <div>
                        <span> 
                        <IconButton aria-label="user">
                        <AccountCircleIcon>
                        </AccountCircleIcon>
                        </IconButton>{userName}</span>
                    </div> */}
                </div>
                
                <br></br>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                    >
                    <Box sx={{textAlign:'center',marginBottom:'25px'}}>
                        <select onChange={this.changeDownloadOptions}>
                            <option value="0">Tất cả</option>
                            <option value="1">Đang hiển thị</option>
                        </select>
                        <ColorButton sx={{fontSize:'14px'}} onClick={this.download} variant="extended" size='' color="primary">
                        <DownloadIcon sx={{marginRight:'2px'}} />Tải dữ liệu
                        </ColorButton>
                    </Box>
                    <div>
                    <ColorButton sx={{fontSize:'14px', marginRight:"10px"}} onClick={() => this.download({code : true})} variant="extended" size="small" color="primary" padding="4px 6px">
                    <DescriptionIcon sx={{marginRight:'2px'}}/> Báo cáo
                        </ColorButton>
                    </div>
                </div>
                <br></br>
                <table className='thaco' style={{
                            border: "solid 1px #000"
                        }} >
                    <HeaderTable baoCaoNgay={this.state.baoCaoNgay} />
                    <BodyBaoCao
                        ref={this.body}
                        baoCaoNgay={this.state.baoCaoNgay}
                        columnCodes={this.columnCodes}
                        colConfArray={this.state.colArray}
                        rowConfArray={this.state.rowArray}
                        user={this.state.user} />
                </table>
                
            </Box>);
    }
}
 
export default ThongKeDiemDanhPage;