import axios from "axios";
import dayjs from "dayjs";
import Cons from "../utils/Cons";

const SERVER_DOMAIN = Cons.SERVER_URL;
const PUBLIC_URL = ""
const LOGIN_URL = "https://workplace.thacoagri.com.vn/"

const RequestHelper = {
    intit(startRequest, stopRequest, onError) {
        this.BASE_URL = SERVER_DOMAIN + PUBLIC_URL;
        this.conf = null;
        this.startRequest = startRequest;
        this.stopRequest = stopRequest;
        this.onError = onError;
    },

    parseCookie() {
        return document.cookie.split('; ').reduce((prev, current) => {
            const [name, ...value] = current.split('=');
            prev[name] = value.join('=');
            return prev;
        }, {});
    },

    async getLoginInfo() {
        if (!this.loginInfo) {
            const user = await this.get('/api/user');
            this.loginInfo = {userName : user.name};
        }
        return this.loginInfo;
    },

    /**
     * 
     * @param {function} func hàm gọi
     * @param {array} args danh sách tham số
     * @returns giá trị nhận được từ server
     */
    async wrapperExcute(func, args) {
        try {
            if (this.startRequest) this.startRequest();
            // Test
            const cookie = this.parseCookie();
            // Test
            let axiosConfig = {
                headers: {
                    'Authorization': cookie.token,
                },
            };
            args.timeout = 10000*60*10;
            let response = await func(...args, axiosConfig);
            return response.data;
        } catch(ex) {
            this.handleError(ex);
        }
        finally {
            if (this.stopRequest) this.stopRequest();
        }
    },

    handleError(ex) {
        if (ex instanceof EvalError) {
            this.onError("Lỗi kết nối mạng. Vui lòng thông báo với quản trị viên để biết thông báo về lỗi này.");
            return;
        }
        if (ex.response.status === 401) {
            window.location.href = LOGIN_URL;
            return;
        }
        if (ex.response.status === 404) {
            this.onError("Xin lỗi! Đường dẫn truy cập không tồn tại!");
            return;
        }
        if (ex.response.status === 500) {
            this.onError("Xin lỗi! Có lỗi hệ thống. Vui lòng thông báo với quản trị viên để biết thông báo về lỗi này.");
            return;
        }
        this.onError(ex.response.message);
        //throw ex;
    },

    /**
     * Get an object by url
     * @param {String} url url
     * @param {Object} query object query
     * @returns get json data
     */
    async get(url, query=null) {
        var queryString = query == null ? "" : Object.keys(query).map(key => key + '=' + query[key]).join('&');
        queryString = !queryString ? "" : '?' + queryString;
        return await this.wrapperExcute(axios.get, [this.BASE_URL + url + queryString]);
    },

    /**
     * Gọi api edit
     * @param {string} url đường dẫn
     * @param {Object} postData dữ liệu post
     * @returns giá trị sau khi post
     */
    async post(url, postData){
        return await this.wrapperExcute(axios.post, [this.BASE_URL + url, postData]);
    },

    /**
     * Gọi api add
     * @param {string} url đường dẫn
     * @param {Object} postData dữ liệu post
     * @returns giá trị sau khi post
     */
    async put(url, putData){
        return await this.wrapperExcute(axios.put, [this.BASE_URL + url, putData]);
    },

    /**
     * Gọi api delete
     * @param {string} url đường dẫn
     * @param {Object} id dữ liệu dòng muốn xoá
     * @returns giá trị sau khi post
     */
    async delete(url){
        return await this.wrapperExcute(axios.delete, [this.BASE_URL + url]);
    },

    async download(url, query, preFileName = "") {
        try {
            var queryString = query == null ? "" : Object.keys(query).map(key => key + '=' + query[key]).join('&');
            queryString = !queryString ? "" : '?' + queryString;
            const cookie = this.parseCookie();
            const response = await axios.get(this.BASE_URL + url + queryString, {
                headers: {
                    'Authorization': cookie.token,
                },
                responseType: 'blob'
            });
            const downloadURL = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadURL;

            let fileName =`${preFileName}_${dayjs(new Date()).format("YYYYMMDD")}.xlsx`;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        } catch (ex) {
            this.handleError(ex);
        }
    },
}

export default RequestHelper;