import React, { Component } from 'react';
import TreeUtils from '../../utils/TreeUtils';
import './Table.css';

class HeaderTable extends Component {
    buildHeader() {
        if (!this.props.baoCaoNgay) return '';
        let cols = TreeUtils.flatTree(this.props.baoCaoNgay.colConfig)
        let array = [];
        for (let i = 0; i < this.props.baoCaoNgay.colConfig.maxRowLevel; i++) {
            array.push(this.makeHeaderRows(cols, i, this.props.baoCaoNgay.colConfig.maxRowLevel));
        }
        return array;
    }

    makeHeaderRows(cols, i, rowSpan) {
        let rowCols = cols.filter(q => q.code.split('.').length == i + 1);
        return (
            <tr>
            {
                i == 0 ?
                (
                    <>
                    <th rowSpan={rowSpan}>
                        
                    </th>
                    <th style={{width: '30px'}} rowSpan={rowSpan}>
                        STT
                    </th>    
                    <th rowSpan={rowSpan}>
                        Tên Phòng Ban
                    </th>
                    </>
                )
                :
                ""
            }
            {
                this.props.isImportPB ?
                ""
                :
                rowCols.map(q => <th key={"col" + q.code} rowSpan={q.room[1]} colSpan={q.room[0]}>{q.name}</th>)
            }
            </tr>
        );
    }

    render() {
        const header = this.buildHeader();
        return(
            <thead>
                {header}
            </thead>
        );
    }
}
 
export default HeaderTable;