import requestHelper from "./requestHelper";

class UserService {

    constructor() {
        this.requestHelper = requestHelper;
    }

    setMaskEvent(onStartRequest, onStopRequest, onError) {
        requestHelper.startRequest = onStartRequest;
        requestHelper.stopRequest = onStopRequest;
        requestHelper.onError = onError;
    }

    getCurrent = async () => {
        let insertData = await requestHelper.get('/api/user');
        return insertData;
    }
}

export default UserService;