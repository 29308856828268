import ExcelJS from "exceljs";
import TreeUtils from "./TreeUtils";
import ReCalUtil from "./RecalUtil";

const ExcelImport = {

    init(bcNgay, colConfArray, rowConfArray,) {
        this.rowConfArray = rowConfArray;
        this.colConfArray = colConfArray;
    },

    importExcel(fileRes, loadFunc, userName, onlyPB = false) {
        if (!fileRes) return;
        const wb = new ExcelJS.Workbook();
        const reader = new FileReader();
        reader.readAsArrayBuffer(fileRes);
        reader.onload = () => {
            const buffer = reader.result;
            wb.xlsx.load(buffer).then(workbook => {
                const sheet = workbook.worksheets[0];
                const excelRowConfs = [];
                const excelData = {};

                let rowNumber = -1;
                for (let row of sheet._rows) {
                    rowNumber++;
                    if (rowNumber < 3) continue;
                    if (!row.values[1]) continue;
                    let excelRowConf = {};
                    excelRowConf.name = row.values[2];
                    excelRowConf.code = row.values[1].toString();
                    if (onlyPB) {
                        if (row.values[3] && row.values[3]?.text) {
                            excelRowConf.forAccounts = row.values[3]?.text.split(',').map(q => q.trim());
                        } else if (row.values[3]) {
                            excelRowConf.forAccounts = row.values[3].split(',').map(q => q.trim());
                        }
                    }
                    const rowConf = TreeUtils.findNodeByCode(this.rowConfArray, excelRowConf.code);
                    if (!rowConf) {
                        excelRowConf.key = TreeUtils.getRandomKey();
                    } else {
                        excelRowConf.key = rowConf.key;
                    }
                    excelRowConfs.push(excelRowConf);

                    if (!onlyPB) {
                        for (let i = 0 ; i < this.colConfArray.length; i++) {
                            this.getCellValue(row, excelRowConf, i, excelData);
                        }
                    }
                }
                let errors = {};
                let rowErrors = {};
                this.checkRowConf(excelRowConfs, rowErrors, userName);
                if (!onlyPB) {
                    if (Object.keys(errors).length === 0) {
                        this.checkData(excelData, excelRowConfs, errors);
                    }
                }

                // tìm các dòng không đúng định dạng
                let deleteRows = TreeUtils.findInRoles(this.rowConfArray, userName);
                let excelCodes = excelRowConfs.map(q => q.code);
                deleteRows = deleteRows.filter(q => !excelCodes.includes(q.code));
                loadFunc({
                    excelData,
                    excelRowConfs,
                    errors,
                    rowErrors,
                    deleteRows
                });
            })
        }
    },

    getCellValue(excelRow, excelRowConf, colIndex, excelData)
    {
        let colConf = this.colConfArray[colIndex];
        let key = TreeUtils.getDataKey(colConf, excelRowConf);
        let value = excelRow.values[3 + colIndex];
        if (colConf.cType == 'TEXT') {
            if (typeof value === 'string') {
                excelData[key] = value;
            }
            return;
        }
        if (value && value.result) {
            value = value.result;
        }
        if (isNaN(value)) value = 0;
        excelData[key] = value;
    },

    checkRowConf(excelRowConfs, errors, userName) {
        let treeInRoles = TreeUtils.findInRoles(this.rowConfArray, userName);
        let firstCode = treeInRoles[0].code;
        let preCode = ""
        for (let i = 0; i < excelRowConfs.length; i++) {
            let rowConf = TreeUtils.findNodeByCode(treeInRoles, excelRowConfs[i].code);
            if (!rowConf) {
                let error = this.checkCode(preCode, firstCode, excelRowConfs[i].code);
                if (error) {
                    errors[0 + " " + i] = error;
                } else {
                    errors[0 + " " + i] = {pri: 1, message: "Mã thêm mới"};
                }
            }
            const excelRowConf =  excelRowConfs[i];
            preCode = excelRowConf.code;
            if (rowConf && !(rowConf.name === excelRowConf.name)) {
                errors[1 + " " + i] = {pri: 1, message: "Tên cột đã sửa"};
            }
            
            if (excelRowConf.forAccounts && !excelRowConf.forAccounts.some(q => q.endsWith('@thaco.com.vn'))) {
                errors[2 + " " + i] = {pri: 0, message: "Tài khoản phải kết thúc bằng '@thaco.com.vn'"};
            }
        }
    },

    checkCode(preCode, firstCode, code) {
        try {
            let regex = /[0-9]{1,2}(\.[0-9]{1,2})*/g;
            if (!regex.test(code)) {
                return {pri: 0, message: "Mã không đúng định dạng"};
            }
            if (!code.startsWith(firstCode)) {
                return {pri: 0, message: "Mã không thuộc phân quyền"};
            }
            if (!preCode) return null;
            const codeSplit = code.split('.');
            const preCodeSplit = preCode.split('.');
            if (codeSplit.length > preCodeSplit.length) {
                if (codeSplit.length !== preCodeSplit.length + 1) {
                    return {pri: 0, message: "Mã không khớp với mã trước đó"};
                }
                if (codeSplit[preCodeSplit.length] !== '0') {
                    return {pri: 0, message: "Số cuối của mã phải là không"};
                }
            }
            if (codeSplit.length <= preCodeSplit.length) {
                if (parseInt(preCodeSplit[codeSplit.length - 1]) + 1 !== parseInt(codeSplit[codeSplit.length - 1])) {
                    return {pri: 0, message: "Mã không khớp với mã trước đó"};
                }
            }
        } catch (ex) {
            return {pri: 0, message: "Mã không hợp lệ"};
        }
        return null;
    },

    checkData(excelData, rowConfs, errors) {
        TreeUtils.setHasChild(rowConfs);
        const reCalUtil = new ReCalUtil(rowConfs, this.colConfArray);
        let checkData = {};
        for (let rowIndex = 0; rowIndex < rowConfs.length; rowIndex++) {
            let rowConf = rowConfs[rowIndex];
            for (let colIndex = 0; colIndex < this.colConfArray.length; colIndex++) {
                let colConf = this.colConfArray[colIndex];
                if (!rowConf.hasChild && !colConf.func) {
                    let value = excelData[colConf.key + " " + rowConf.key];
                    checkData = reCalUtil.changeData(value, colIndex, rowIndex, checkData);
                }
            }
        }

        reCalUtil.calculateCheck(checkData);

        for (let key in checkData) {
            if (!checkData[key] && !excelData[key]) continue;
            if (!key.includes('c_')) {
                if (checkData[key] !== excelData[key]) {
                    errors[key] = "Dữ liệu tính toán không phù hợp";
                    continue;
                }
            } else {
                let normalKey = key.replace("c_", "");
                if (checkData[key]) {
                    if (checkData[normalKey] !== checkData[key]) {
                        errors[normalKey] = "Dữ liệu tính toán không đúng với kết quả kiểm tra liên quan";
                    }
                }
            }
        }
    }
}

export default ExcelImport;