const TreeUtils = {

    getRandomKey() {
        return Math.random().toString(16).slice(2) + "";
    },

    addKeyToCol(node) {
        if (!node.key) node.key = this.getRandomKey();
        if (!node.child || node.child.length == 0) return;
        for (const child of node.child) {
            this.addKeyToCol(child);
        }
    },

    getDataKey(colConf, rowConf) {
        return colConf.key + " " + rowConf.key;
    },
    
    flatTree(tree) {
        let cols = [];
        this.pushToFlatArray(tree, cols);
        cols.shift();
        return cols;
    },

    pushToFlatArray(col, cols, parentCode = null, cIndex = null) {
        let newCol = Object.assign({}, col);
        if (!parentCode) {
            if (cIndex == null) {
                newCol.code = "";
            } else {
                newCol.code = cIndex.toString();
            }
        } else {
            newCol.code = parentCode.toString() + "." + cIndex.toString();
        }
        delete newCol.child;
        newCol.hasChild = col.child && col.child.length > 0;
        cols.push(newCol);
        if (newCol.hasChild) {
            let cIndex = 0;
            for (const childCol of col.child) {
                this.pushToFlatArray(childCol, cols, newCol.code, cIndex);
                cIndex++;
            }
        }
    },

    loadForAccount(rows, rootAccounts = []) {
        for (let row of rows) {
            let parents = this.findAllParent(rows, row.code);
            let forAccounts = [].concat(rootAccounts);
            parents = parents.filter(q => q.forAccounts)
            for (let parent of parents) {
                forAccounts = forAccounts.concat(parent.forAccounts);
            }
            if (row.forAccounts) {
                forAccounts = forAccounts.concat(row.forAccounts);
            }
            row.forAccounts = [...new Set(forAccounts)];
        }
    },

    getMaxLevelArray(tree) {
        const maxLevelArray = [];
        this.putNodeToMaxLevelArray(tree, maxLevelArray);
        return maxLevelArray;
    },

    putNodeToMaxLevelArray(node, maxLevelArray) {
        if (!node.child || node.child.length === 0) {
            const currentNode = Object.assign({}, node);
            delete currentNode.child;
            maxLevelArray.push(currentNode);
        } else {
            for (let childNode of node.child) {
                this.putNodeToMaxLevelArray(childNode, maxLevelArray);
            }
        }
    },

    findParentCode(childCode) {
        let parentCode = childCode;
        parentCode = parentCode.split(".");
        if (parentCode.lenght == 1) return null;
        parentCode.pop();
        parentCode = parentCode.join('.');
        return parentCode;
    },

    findIndexByCode(treeArr, code) {
        let cRow = treeArr.filter(q => q.code == code);
        cRow = cRow[0];
        cRow = treeArr.indexOf(cRow);
        return cRow;
    },

    findIndexByKey(treeArr, key) {
        let cRow = treeArr.filter(q => q.key == key);
        cRow = cRow[0];
        cRow = treeArr.indexOf(cRow);
        return cRow;
    },

    findNodeByCode(treeArr, code) {
        let cRow = treeArr.filter(q => q.code == code);
        cRow = cRow[0];
        return cRow;
    },

    findAllParent(treeArr, childCode, outType = 1) {
        let parentSlit = childCode.split('.');
        parentSlit.pop();
        let parents = [];
        let preParentCode = "";
        for (let i = 0; i < parentSlit.length; i++) {
            let parentCode = preParentCode + (!preParentCode ? "" : ".") + parentSlit[i];
            let parent = treeArr.filter(q => q.code === parentCode);
            parent = parent[0];
            if (!parent) continue;
            if (outType === 1) {
                parents.push(parent);
            } else {
                parents.push(treeArr.indexOf(parent));
            }
            preParentCode = parentCode;
        }
        return parents;
    },

    findAllChild(treeArr, parentCode) {
        let parentLevel = parentCode.split('.').length;
        return treeArr.filter(q => q.code.split('.').length == parentLevel + 1 && q.code.startsWith(parentCode + "."));
    },

    addExcelColumnName(colConfArray, startEcelColName) {
        let excelCol = startEcelColName;
        for (const columnConf of colConfArray) {
            const cExcelCol = excelCol + "";
            columnConf.excelCol = cExcelCol;
            excelCol = this.getShiftColLabel(excelCol, 1);
        }
    },

    getShiftCellLabel(colName, rowName, shiftCol, shiftRow) {
        let toColName = String.fromCharCode(colName.charCodeAt(0) + shiftCol - 1);
        let toRowName = rowName + shiftRow - 1;
        return {toColName, toRowName};
    },

    addRowName(rowConfArray, sRowExcelIndex, exportOption = {}) {
        let rowExcelIndex = sRowExcelIndex;
        for (let rowConf of rowConfArray) {
            if (this.getVisible(rowConfArray, rowConf, exportOption)) {
                const rowIndex = rowExcelIndex + 0;
                rowConf.excelRow = rowIndex;
                rowExcelIndex++;
            }
        }
    },

    getVisible(rowConfArray, rowConf, exportOption) {
        const isExpand = !!exportOption.expands;
        const code = !!exportOption.nodeCode;
        if (code) {
            let startCode = exportOption.nodeCode  + ".";
            return rowConf.code.startsWith(startCode);
        }
        if (isExpand) {
            let visible = true;
            let parentIndexes = TreeUtils.findAllParent(rowConfArray, rowConf.code, 0);
            parentIndexes = parentIndexes.filter((pRowIndex, index, arr) =>
                {
                    return exportOption.expands[pRowIndex] == false && rowConfArray[pRowIndex].forAccounts.indexOf(exportOption.userName) > -1
                }
            );
            visible = parentIndexes.length > 0 ? false : true;
            visible = visible && (rowConf.forAccounts.indexOf(exportOption.userName) > -1);
            return visible;
        }
        return rowConf.forAccounts.indexOf(exportOption.userName) > -1;
    },

    getShiftColLabel(colName, shiftCol) {
        let toColName = String.fromCharCode(colName.charCodeAt(0) + shiftCol);
        return toColName;
    },

    setHasChild(rowConfs) {
        for (let rowConf of rowConfs) {
            rowConf.hasChild = rowConfs.filter(q => q.code.startsWith(rowConf.code + ".")).length > 0;
        }
    },

    findIndexByKey(treeArr, key) {
        let cRow = treeArr.filter(q => q.key == key);
        cRow = cRow[0];
        cRow = treeArr.indexOf(cRow);
        return cRow;
    },

    findInRoles(treeArr, userName) {
        const rowInRoles = treeArr.filter(q => q.forAccounts.includes(userName));
        return rowInRoles;
    }
}

export default TreeUtils;