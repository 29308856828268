import ExcelJS from "exceljs";
import validator from 'validator';

const ImportDanhSach = {

    init: function(phongbans, configs) {
        this.phongbans = phongbans;
        this.configs = configs;
        this.configs.phongbans = phongbans.map(q => q.code + " - " + q.name);
    },

    dataMap : [
        {
            name: "Số thứ tự",
            feild: "stt",
        },
        {
            name: "MSNV",
            feild: "empCode",
            fType: 'text',
            validate: function(value, rowData, allData, phongBans) {
                if (!value) return "Bắt buộc phải nhập mã số nhân viên";
                value = value.toString();
                if (!validator.isNumeric(value)) {
                    return "Mã số nhân viên phải là chuỗi số";
                }
                const msnvs = allData.map(q => q.empCode);
                if (msnvs.includes(value)) {
                    return "Mã số nhân viên trùng lặp trong file";
                }
                return null;
            }
        },
        {
            name: "Tên",
            feild: "profileName",
            validate: function(value) {
                if (!value) return "Yêu cầu nhập tên";
                return null;
            }
        },
        {
            name: "Chức vụ",
            feild: "PositionOnlyName",
            validate: function(value) {
                if (!value) return "Yêu cầu nhập chức vụ";
            }
        },
        {
            name: "Hiệu hữu",
            feild: "status",
            validate: function(value, rowValue, allData, phongbans, others) {
                if (!others.status.includes(value)) {
                    return "Hiện hữu không đúng với danh sách chọn"
                }
            },
            enum: "status"
        },
        {
            name: "Điểm danh",
            feild: "typeOfDate",
            validate: function(value, rowValue, allData, phongbans, others) {
                if (!value && (rowValue.status === others.status[2] || rowValue.status === others.status[4])) {
                    return null;
                }
                if (!others.typeOfDate.includes(value)) {
                    return "Điểm danh không đúng với danh sách chọn"
                }
            },
            enum: "typeOfDate"
        },
        {
            name: "Lý do nghỉ",
            feild: "reason",
            validate: function(value, rowValue, allData, phongbans, others) {
                if (!value) {
                    if (rowValue.typeOfDate === others.typeOfDate[3]) {
                        return "Loại điểm danh vắng mặt phải có lý do nghỉ";
                    }
                    return;
                }
                if (!others.reason.includes(value)) {
                    return "Lý do nghỉ không đúng với danh sách chọn"
                }
            },
            enum: 'reason',
        },
        {
            name: "Tăng ca",
            feild: "overTime",
            validate: function(value, rowValue, allData, phongbans, others) {
                if (!value) return;
                if (!others.overTime.includes(value)) {
                    return "Tăng ca không đúng danh sách chọn"
                }
            },
            enum: 'overTime',
        },
        {
            name: "Phòng ban",
            feild: "phongBanCode",
            validate: (value, rowValue, allData, phongbans, others) => {
                if (!phongbans.includes(value)) {
                    return "Phòng ban không đúng với danh sách chọn"
                }
            },
            enum: 'phongbans',
        },
        {
            name: "Ghi chú",
            feild: "note",
        }
    ],

    importExcel(fileRes, loadFunc, startRow = 1) {
        if (!fileRes) return;
        const wb = new ExcelJS.Workbook();
        const reader = new FileReader();
        reader.readAsArrayBuffer(fileRes);
        reader.onload = () => {
            const buffer = reader.result;
            wb.xlsx.load(buffer).then(workbook => {
                const sheet = workbook.worksheets[0];
                const importData = [];
                const errors = {};
                let index = 0;
                const phongbans = this.phongbans.map(q => q.code + " - " + q.name);
                for (let row of sheet._rows) {
                    if (index >= startRow && row.values[2]) {
                        const empInfo = {}
                        for (let i = 0; i < this.dataMap.length; i++) {
                            let value = row.values[i + 1];
                            if (this.dataMap[i].fType === 'text') {
                                value = value ? "" : value.toString();
                            }
                            empInfo[this.dataMap[i].feild] = row.values[i + 1];
                            if (this.dataMap[i].validate) {
                                let error = "";
                                error = this.dataMap[i].validate(row.values[i + 1], empInfo, importData, phongbans, this.configs);
                                if (error) {
                                    errors[index + "_" + i] = error;
                                }
                            }
                        }
                        importData.push(empInfo);
                    };
                    index++;
                }
                
                loadFunc({
                    importData,
                    errors
                });
            })
        }
    },
}

export default ImportDanhSach;