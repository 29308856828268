import React, { Component } from 'react';
import ImportDanhSach from '../utils/ImportDanhSach';
import DiemDanhService from '../services/DiemDanhService';
import dayjs from 'dayjs';
import importFileExcelBuilder from '../utils/createImportFile';
import { Box } from '@mui/material';
const diemDanhService = new DiemDanhService();

class DiemDanhNgayPage extends Component {

    state = { 
        importData : [],
        selectedDate: dayjs(new Date).format('YYYY-MM-DD'),
        phongbans: [],
        phongBan: null,
        rErrors: {},
        enableSave: false,
    }

    componentDidMount() {
        this.changeDate(this.state.selectedDate);
    }

    changeDate = (selectedDate) => {
        diemDanhService.getEnum().then((configs) => {
            diemDanhService.getPhongBan(selectedDate).then((phongbans) => {
                this.setState({phongBan: phongbans[0].key})
                this.setState({phongbans});
                ImportDanhSach.init(phongbans, configs);
            })
        });
    }

    importData = (ev) => {
        let file = ev.currentTarget.files[0];
        if (!file) return;
        ImportDanhSach.importExcel(file, this.loadDataImport);
    }

    loadDataImport = (dataImport) => {
        this.setState(dataImport);
        this.validatePreImport(dataImport);
        document.getElementById("file_input").value = "";
    }

    validatePreImport(dataImport) {
        const enableSave = Object.keys(dataImport.errors).length == 0;
        if (!enableSave) {
            this.setState({enableSave});
            return;
        }
        let data = {};
        data.list = dataImport.importData;
        data.date = this.selectedDate;

        diemDanhService.validate(data).then((rErrors) => {
            const enableSave = Object.keys(rErrors).length == 0;
            this.setState({rErrors, enableSave});
        });
    }

    chooseFile = () => {
        document.getElementById('file_input').click();
    }

    saveImport = () => {
        let data = {};
        data.list = this.state.importData;
        data.date = this.state.selectedDate;
        data.phongBanKey = this.state.phongBan;

        diemDanhService.import(data).then((rErrors) => {
            window.location.href = "/";
        }).catch(() => {
            alert("Không lưu được điểm danh");
        })
    }

    downloadImportFile = () => {
        diemDanhService.getLastedImport(this.state.selectedDate).then((lastedImport) => {
            const excel = importFileExcelBuilder.build(this.state.phongbans, lastedImport);
            excel.xlsx.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer]);
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = url;
                a.download = "FileImport" + dayjs(new Date()).format("_DD_MM_YYYY") + ".xlsx";
                a.click();
                window.URL.revokeObjectURL(url);
            })
        });
    }

    makeRow(rowData, rowIndex) {
        let keys = ImportDanhSach.dataMap.map(q => q.feild);
        const cells = [];
        let colIndex = 0;
        let rowDupError = "";
        for (let key of keys) {
            // set background color by import errors
            let backgroundColor = this.state.errors[rowIndex + "_" + colIndex] ? 'red' : 'white';
            // set background color by response errors
            let value = rowData[key];
            if (key == 'empCode') {
                backgroundColor = this.state.rErrors[value] ? 'red' : backgroundColor;
                rowDupError = this.state.rErrors[value];
            }

            let colInfo = ImportDanhSach.dataMap[colIndex];

            if (value && colInfo.format) {
                value = colInfo.format(value);
            }
            cells.push(<td style={{backgroundColor}}>{!value ? "" : value.toString()}</td>);
            colIndex++;
        }
        let errorKeys = Object.keys(this.state.errors);
        errorKeys = errorKeys.filter(q => q.startsWith(rowIndex + "_"));
        if ((errorKeys && errorKeys.length > 0) || rowDupError) {
            let errorMessages = []
            for (let errorKey of errorKeys) {
                errorMessages.push(this.state.errors[errorKey]);
            }
            if (rowDupError) {
                errorMessages.push(rowDupError);
            }
            cells.push(<td>{errorMessages.join(', ')}</td>);
        } else {
            cells.push(<td></td>);
        }
        return cells;
    }

    makeBody() {
        const rows = [];
        let rowIndex = 1;
        for (let row of this.state.importData) {
            rows.push(<tr>
                {this.makeRow(row, rowIndex)}
            </tr>);
            rowIndex++;
        }
        return rows;
    }

    render() {
        return (
            <Box>
            <Box sx={{ width: '100%', typography: 'body1', padding:'10px',fontSize:'20px',textAlign: 'center', fontFamily:'ui-rounded', fontWeight:'700' }}>ĐIỂM DANH NHÂN SỰ HẰNG NGÀY</Box>
            <div>
                <span>Ngày nhập: </span>
                <input
                type="date"
                value={this.state.selectedDate}
                onChange={(ev) => {this.setState({selectedDate: ev.target.value})}} />
                </div>
            <div>
                <table>
                    <thead>
                        <tr>
                            {ImportDanhSach.dataMap.map(q => <th>{q.name}</th>)}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.makeBody()}
                    </tbody>
                </table>
            </div>
            <div>
                <input value='Import' type="button" onClick={this.chooseFile} />
                <input type='file' id="file_input" style={{display: 'none'}} onChange={this.importData} />
                <button type='button' onClick={this.downloadImportFile} >Tải file import</button>
                <button disabled={!this.state.enableSave} type='button' onClick={this.saveImport} >Lưu</button>
            </div>
            </Box>
        );
    }
}
 
export default DiemDanhNgayPage;