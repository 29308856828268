import requestHelper from "./requestHelper";
import dayjs from "dayjs";

class DiemDanhService {

    constructor() {
        this.requestHelper = requestHelper;
    }

    getPhongBan = async(date) => {
        const dateStr = dayjs(date).format("YYYY-MM-DD");
        let phongbans = await requestHelper.get(`/api/diemDanhNgay/getPB/${dateStr}`);
        return phongbans;
    }

    getEnum = async() => {
        let enums = await requestHelper.get(`/api/getEnum`);
        return enums;
    }

    import = async(data) => {
        data.date = dayjs(data.date).format("YYYY-MM-DD");
        let baoCao = await requestHelper.post(`/api/diemDanhNgay/import`, data);
        return baoCao;
    }

    validate = async(data) => {
        data.date = dayjs(data.date).format("YYYY-MM-DD");
        let baoCao = await requestHelper.post(`/api/diemDanhNgay/validate`, data);
        return baoCao;
    }

    getLastedImport = async(date) => {
        let data = {};
        data.date = dayjs(date).format("YYYY-MM-DD");
        data.sameDate = false;
        let baoCao = await requestHelper.post(`/api/diemDanhNgay/lastedImport`, data);
        return baoCao;
    }
}

export default DiemDanhService;