import requestHelper from "./requestHelper";
import dayjs from "dayjs";

class BaoCaoNgayService {

    constructor() {
        this.requestHelper = requestHelper;
    }

    getByDate = async(date) => {
        const dateStr = dayjs(date).format('YYYY-MM-DD')
        let baoCao = await requestHelper.get(`/api/baoCaoNgay/${dateStr}`);
        return baoCao;
    }

    getList = async(query) => {
        let insertData = await requestHelper.get(`/api/baoCaoNgay`, query);
        return insertData;
    }

    import = async(data) => {
        let updateData = await requestHelper.post('/api/baoCaoNgay/import', data);
        return updateData;
    }

    update = async(data) => {
        let updateData = await requestHelper.post('/api/baoCaoNgay/save', data);
        return updateData;
    }
}

export default BaoCaoNgayService;