import React, { Component } from 'react';
import HeaderTable from './Header';
import BodyTable from './Body';

class ImportTable extends Component {

    state = {
    }

    render() {
        let disableImport = false;
        for (const key in this.props.importData.rowErrors) {
            if (this.props.importData.rowErrors[key].pri === 0) {
                disableImport = true;
                break;
            }
        }
        for (const key in this.props.importData.errors) {
            if (this.props.importData.errors[key]) {
                disableImport = true;
                break;
            }
        }

        return (
            <div
                style={{
                    width: "100vw",
                    height: "100vw",
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    background: "rgba(0, 0, 0, 0.6)",
                    padding: "20px 0px 0px 0px",
                    display: this.props.visible ? "block" : "none"
                }}
                >
                <div
                    style={{backgroundColor: "white"}}>
                    <div>
                        Dữ liệu import
                    </div>
                    <table style={{
                                background: "#fff",
                                border: "solid 1px #000"
                            }} >
                        <HeaderTable
                            isImportPB={this.props.isImportPB}
                            baoCaoNgay={this.props.baoCaoNgay} />
                        <BodyTable
                            isImportPB={this.props.isImportPB}
                            baoCaoNgay={this.props.baoCaoNgay}
                            columnCodes={this.props.columnCodes}
                            colConfArray={this.props.colConfArray}
                            rowConfArray={this.props.importData.excelRowConfs}
                            user={this.props.user}
                            importData={this.props.importData}
                            importMode={true}/>
                    </table>
                    <div>
                        <span>
                            Danh sách dòng bị xoá
                        </span>
                        <ul>
                        {
                            this.props.importData.deleteRows ? this.props.importData.deleteRows.map(
                                (q) => <li>{q.code} - {q.name}</li>)
                            :
                            ""
                        }
                        </ul>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                        <div>
                            <button
                                disabled={disableImport}
                                onClick={(ev) => this.props.saveImport(this.props.excelData)}>
                                Lưu
                            </button>
                        </div>
                        <div>
                            <button onClick={this.props.onCloseClick}>
                                Đóng
                            </button>
                        </div>
                    </div>
                </div>
            </div>);
    }
}
 
export default ImportTable;