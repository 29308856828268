const Cons = {
    SERVER_URL: "https://testnghiphep.thacoagri.com.vn/",
    //SERVER_URL: "http://localhost:3005",
    ColorLevels : [
        '#09af00',
        '#41c300',
        '#61d800',
        '#75e900',
        '#90ee02',
        '#AAF255',
        '#C6F6D8',
        '#DEFABB',
    ],
    HeaderColor: '#34A853',
    C_TYPE : ['TEXT', 'INT', 'PERCENT'],
    DIEM_DANH: ['Làm giờ hành chính', 'Làm Ca 2 + Ca 3', 'Công tác', 'Vắng mặt', 'Tăng ca'],
    HIEN_HUU: ["Đang làm việc", "Tuyển mới", "Nghỉ việc", "Điều chuyển đến", "Điều chuyển đi"],
    TONG_HOP_NAME: 'THACO AGRI',
    PERCENT_FORMAT: "0.0%"
}

export default Cons;