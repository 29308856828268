import "./App.css";
import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Alert, Backdrop, CircularProgress, Snackbar } from "@mui/material";
import BaoCaoNgayPage from "./pages/BaoCaoNgayPage";
import DiemDanhNgayPage from "./pages/DiemDanhNgayPage";
import ThongKeDiemDanhPage from "./pages/ThongKeDiemDanhPage";
import ImportKetQuaPage from "./pages/ImportKetQuaPage";
import CauTrucPhongBanPage from "./pages/CauTrucPhongBanPage";
import RequestHelper from "./services/requestHelper";
import NavBar from "./compoments/navBar";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import UserService from './services/UserService';
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';

const userService = new UserService();

function App() {
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("error");
  const [user, setUser] = React.useState({});

  const onSuccess = (message) => {
    setSeverity("success")
    setOpenError(true);
    setErrorMessage(message);
  }

  const router = createBrowserRouter([
    {
      path: "/thong-ke",
      element: <BaoCaoNgayPage onSuccess={onSuccess} />,
    },
    {
      path: "/import",
      element: <DiemDanhNgayPage onSuccess={onSuccess} />,
    },
    {
      path: "/importkq",
      element: <ImportKetQuaPage onSuccess={onSuccess} />,
    },
    {
      path: "/cautruc",
      element: <CauTrucPhongBanPage onSuccess={onSuccess} />,
    },
    {
      path: "/",
      element: <ThongKeDiemDanhPage onSuccess={onSuccess} />,
    }
  ])

  const onStartRequest = () => {
    setOpen(true);
  }

  const onStopRequest = () => {
    setOpen(false);
  }

  const onError = (message) => {
    setSeverity("error")
    setOpenError(true);
    setErrorMessage(message);
  }

  React.useEffect(() => {
    userService.getCurrent().then((user) => {
      setUser(user);
    });
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };

  RequestHelper.intit(onStartRequest, onStopRequest, onError);

  return (
    <>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static"
      color="transparent">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <img src='/bg/logo-agri.png' alt='logo' width='300' height='50' />
          </Typography>
        <div>
        <span> 
          <IconButton aria-label="user" sx={{textAlign:'right'}}>
          <AccountCircleIcon>
          </AccountCircleIcon>
          </IconButton>{user.name}</span>
        </div>
        </Toolbar>
      </AppBar>
   
      <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        >
        <CircularProgress color="inherit" />
      </Backdrop>
      <NavBar></NavBar>
      <RouterProvider router={router} />
    <Box sx={{ width: 500 }}>
      <BottomNavigation>
        THACO AGRI
      </BottomNavigation>
    </Box>
      </Box>
    </>
  );
}

export default App;
