import React, { Component } from 'react';
import BaoCaoNgayService from '../services/BaoCaoNgayService';
import HeaderTable from '../compoments/bcTable/Header';
import BodyTable from '../compoments/bcTable/Body';
import TreeUtils from '../utils/TreeUtils';
import dayjs from 'dayjs';
import UserService from '../services/UserService';
import excelBuilder from '../utils/excelBuilder';
import ExcelImport from '../utils/excelImport';
import ImportTable from '../compoments/bcTable/ImportTable';
import Button from '@mui/material/Button';
import PublishIcon from '@mui/icons-material/Publish';
import { styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import { Box } from '@mui/material';

const userService = new UserService();
const baoCaoNgayService = new BaoCaoNgayService();

const ColorButton = styled(Button)(({ theme }) => ({
    
    fontFamily: [
        'Arial', 'Helvetica', 'sans-serif'
      ].join(','),
    color: theme.palette.getContrastText(green[700]),
    backgroundColor: green[700],
    '&:hover': {
      backgroundColor: green[900],
    },
  }));
class CauTrucPhongBanPage extends Component {

    constructor(props) {
        super(props);
        this.body = React.createRef();
    }

    state = { 
        baoCaoNgay: null,
        colArray: null,
        rowArray: null,
        selectedDate: dayjs(new Date).format('YYYY-MM-DD'),
        user: null,
        
        visbleImport: false,
        importData : {excelRowConfs : []}
    }

    onChangeSelectedDate = (ev) => {
        let selectedDate = ev.target.value;
        this.changeDate(selectedDate);
        this.setState({selectedDate});
    }

    changeDate = (selectedDate) => {
        userService.getCurrent().then((user) => {
            this.setState({user});
            baoCaoNgayService.getByDate(selectedDate).then((bc) => {
                if (!bc.data) bc.data = {};
                const colArray = TreeUtils.getMaxLevelArray(bc.colConfig);
                this.columnCodes = colArray.map(q => q.code);
                const rowArray = TreeUtils.flatTree(bc.rowConfig);
                TreeUtils.loadForAccount(rowArray, bc.rowConfig.forAccounts);
                this.setState({rowArray, baoCaoNgay: bc, colArray});
            });
        });
    }

    componentDidMount() {
        this.changeDate(this.state.selectedDate);
    }

    importData = (ev) => {
        let file = ev.currentTarget.files[0];
        if (!file) return;
        ExcelImport.init(this.state.bcNgay, this.state.colArray, this.state.rowArray);
        ExcelImport.importExcel(file, this.loadDataImport, this.state.user.name, true);
    }

    loadDataImport = (dataImport) => {
        this.setState({
            visbleImport: true,
            importData: dataImport
        })
        document.getElementById("file_input").value = "";
    }

    saveImport = (data) => {
        let importData = Object.assign({}, this.state.importData);
        importData.id = this.state.baoCaoNgay._id;
        baoCaoNgayService.import(importData).then((bc) => {
            this.changeDate(this.state.selectedDate);
            this.setState({visbleImport: false});
            alert("Đã lưu kết quả");
        }).catch((ex) => {
            alert("Không thể lưu");
        })
    }

    closeImport = () => {
        this.setState({visbleImport: false});
    }

    chooseFile = () => {
        document.getElementById('file_input').click();
    }

    render() {
        let userName = this.state.user ? this.state.user.name : "";
        return (
            <Box>
            <Box sx={{ width: '100%', typography: 'body1', padding:'10px',fontSize:'20px',textAlign: 'center', fontFamily:'ui-rounded', fontWeight:'700' }}>QUẢN LÝ CẤU TRÚC VÀ PHÂN QUYỀN</Box>
            <div>
                <ImportTable
                    isImportPB={true}
                    baoCaoNgay={this.state.baoCaoNgay}
                    columnCodes={this.columnCodes}
                    colConfArray={this.state.colArray}
                    rowConfArray={this.state.rowArray}
                    user={this.state.user}
                    visible={this.state.visbleImport}
                    importData={this.state.importData}
                    saveImport={this.saveImport}
                    onCloseClick={this.closeImport}
                />
                <div>
                <div 
                style={{
                    display: 'flex',
                    //justifyContent: 'space-between'
                }}>
                    <div>
                        <span>Ngày nhập: </span>
                        <input
                            type="date"
                            value={this.state.selectedDate}
                            onChange={this.onChangeSelectedDate} />
                    
                            {/* <span>Nguời dùng : {userName}</span> */}
                    </div>
                    <div>
                        <ColorButton sx={{ fontSize:'13px', padding:'5px 4px 4px 6px', margin:'0px 4px 1px 10px'}} onClick={this.chooseFile}>
                        <PublishIcon sx={{fontSize:'22px'}} />
                            Import
                        </ColorButton>
                        <input
                            style={{
                                display: 'none'
                            }}
                            id="file_input"
                            type="file"
                            accept="excel/xls, excel/xlsx"
                            onChange={this.importData} />
                    </div>   
                    </div>
                </div>
                <br></br>
                <table style={{
                            border: "solid 1px #000"
                        }} >
                    <HeaderTable
                        isImportPB={true}
                        baoCaoNgay={this.state.baoCaoNgay} />
                    <BodyTable
                        isImportPB={true}
                        ref={this.body}
                        baoCaoNgay={this.state.baoCaoNgay}
                        columnCodes={this.columnCodes}
                        colConfArray={this.state.colArray}
                        rowConfArray={this.state.rowArray}
                        user={this.state.user} />
                </table>
                {/* <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                    >
                    <div>
                        <button onClick={this.chooseFile}>
                            Import
                        </button>
                        <input
                            style={{
                                display: 'none'
                            }}
                            id="file_input"
                            type="file"
                            accept="excel/xls, excel/xlsx"
                            onChange={this.importData} />
                    </div>
                </div> */}
            </div>
            </Box>);
    }
}
 
export default CauTrucPhongBanPage;