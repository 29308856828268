import ExcelJS from "exceljs";
import TreeUtils from "./TreeUtils";
import Cons from "./Cons";
import dayjs from "dayjs";

const excelBuilder = {

    init(colConfArray, rowConfArray) {
        this.rowConfArray = rowConfArray;
        this.colConfArray = colConfArray;

        TreeUtils.addExcelColumnName(this.colConfArray, 'C');
    },

    /**
     * Tạo file bao cao
     * @param {BaoCaoNgayModel} baoCaoNgayModel Data and config
     */
    build(baoCaoNgay, exportOption = {}) {
        const workbook = new ExcelJS.Workbook();

        workbook.creator = 'Phan Van Binh';
        workbook.created = new Date();
        workbook.modified = new Date();

        if (exportOption.code) {
            const tongHopSheet = workbook.addWorksheet(Cons.TONG_HOP_NAME);
            this.makeTitle(tongHopSheet, baoCaoNgay);
            this.buildTongHop(tongHopSheet, baoCaoNgay, exportOption);
            let index = 0;
            for (const node of baoCaoNgay.rowConfig.child) {
                this.buildSheetChild(workbook, baoCaoNgay, node, index + "");
                index++;
            }
        } else {
            const sheet = workbook.addWorksheet('Bao Cao');
            this.makeHeader(baoCaoNgay, sheet);
            this.makeBody(baoCaoNgay, sheet, exportOption);
        }
        
        return workbook;
    },

    buildSheetChild(workbook, baoCaoNgay, node, nodeCode) {
        let stt = parseInt(nodeCode) + 1;
        let sheetName = stt + ". " + node.name;
        const sheet = workbook.addWorksheet(sheetName);
        this.makeHeader(baoCaoNgay, sheet);

        const rowMap = this.makeBody(baoCaoNgay, sheet, {nodeCode});
    },

    buildTongHop(sheet, baoCaoNgay, exportOption) {
        const StartIndex = 5;
        const expands = this.rowConfArray.map(q => false);
        this.makeHeader(baoCaoNgay, sheet, StartIndex);

        const rowMap = this.makeBody(baoCaoNgay, sheet, {userName: exportOption.userName, expands}, 5);
        this.makeTonHopRow(sheet, rowMap, StartIndex);
    },

    makeTonHopRow(sheet, rowMap, StartIndex) {
        let colIndex = 'C';
        const excelRow = StartIndex + Object.keys(rowMap).length + 3;
        let cell = sheet.getCell('A' + excelRow);
        this.setStyle(cell, null, null);
        cell = sheet.getCell('B' + excelRow);
        cell.value = "TỔNG CỘNG";
        this.setStyle(cell, null, null);
        for (const colConf of this.colConfArray) {
            cell = sheet.getCell(colIndex + excelRow);
            if (colConf.func && colConf.func.startsWith('p')) {
                this.setColFunc(cell, colConf, {excelRow});
            } else {
                let childs = Object.keys(rowMap).map(q => {return {excelRow : rowMap[q]}});
                this.setCellSumof(cell, colConf, null, childs);
            }
            this.setStyle(cell, null, null);
            colIndex = TreeUtils.getShiftColLabel(colIndex, 1);
        }
    },

    makeTitle(sheet, baoCaoNgay) {
        sheet.mergeCells('B2:T2');
        let titleCell = sheet.getCell('B2');
        titleCell.value = "TỔNG HỢP BÁO BÁO CÔNG ĐI LÀM HẰNG NGÀY THACO AGRI";
        this.setStyle(titleCell, null, 1);

        sheet.mergeCells('G3:I3');
        titleCell = sheet.getCell('G3');
        titleCell.value = "NGÀY BÁO CÁO:";
        this.setStyle(titleCell, null, 1);

        sheet.mergeCells('J3:K3');
        titleCell = sheet.getCell('J3');
        titleCell.value = dayjs(baoCaoNgay.createdDate).format("DD/MM/YYYY");
        this.setStyle(titleCell, null, 1);
    },

    makeBody(bcNgay, sheet, exportOption, startRow = 1) {
        const rowMap = {};
        let rowExcelIndex = bcNgay.colConfig.maxRowLevel + startRow;

        TreeUtils.addRowName(this.rowConfArray, rowExcelIndex, exportOption);
        const expand = exportOption.expands ? 2 : true;
        let rowConfIndex = 0;
        for (let rowConf of this.rowConfArray) {
            if (TreeUtils.getVisible(this.rowConfArray, rowConf, exportOption)) {
                let startColName = 'A';
                sheet.getCell(startColName + rowExcelIndex).value = rowConf.code;
                this.setStyle(sheet.getCell(startColName + rowExcelIndex), rowConf, expand);
                startColName = TreeUtils.getShiftColLabel(startColName, 1);
                sheet.getCell(startColName + rowExcelIndex).value = rowConf.name;
                this.setStyle(sheet.getCell(startColName + rowExcelIndex), rowConf, expand);

                for (const colConf of this.colConfArray) {
                    this.setCellData(sheet, colConf, rowConf, bcNgay.data, exportOption);
                }
                rowMap[rowConf.code] = rowExcelIndex;
                rowExcelIndex++;
            }
            rowConfIndex++;
        }
        return rowMap;
    },

    makeHeader(baoCaoNgay, sheet, startRow = 1) {
        let shiftCell = TreeUtils.getShiftCellLabel('A', startRow, 1, baoCaoNgay.colConfig.maxRowLevel);
        let sttCell = "A" + startRow + ":"+shiftCell.toColName + shiftCell.toRowName;
        sheet.mergeCells(sttCell);
        sheet.getCell("A" + startRow).value = "STT";
        this.setStyle(sheet.getCell("A" + startRow));

        shiftCell = TreeUtils.getShiftCellLabel('B', startRow, 1, baoCaoNgay.colConfig.maxRowLevel);
        sttCell = "B" + startRow + ":"+shiftCell.toColName + shiftCell.toRowName;
        sheet.mergeCells(sttCell);
        sheet.getCell("B" + startRow).value = "Tên đơn vị";
        this.setStyle(sheet.getCell("B" + startRow));

        this.makeHeaderCell(baoCaoNgay.colConfig, sheet, "C", startRow);
    },

    makeHeaderCell(colConf, sheet, colName = 'A', rowName = 1) {
        if (colConf.name) {
            let shiftCell = TreeUtils.getShiftCellLabel(colName, rowName, colConf.room[0], colConf.room[1]);
            const cellMerge = colName + rowName + ":" + shiftCell.toColName + shiftCell.toRowName;
            console.log("cell : " + cellMerge + " -- " + colConf.name);
            sheet.mergeCells(cellMerge);
            const cell = sheet.getCell(colName + rowName);
            cell.value = colConf.name;
            this.setStyle(cell);
        } else {
            colConf.room = [0, 0];
        }
        if (!colConf.child || colConf.child.length == 0) return;

        let shiftColName = colName;
        for (const childColConf of colConf.child) {
            this.makeHeaderCell(childColConf, sheet, shiftColName, rowName + colConf.room[1]);
            shiftColName = TreeUtils.getShiftColLabel(shiftColName, childColConf.room[0]);
        }
    },

    /**
     * 
     * @param {*} cell 
     * @param {*} rowConfig 
     * @param {*} For null is default, 1 is title, 2 is expands
     */
    setStyle(cell, rowConfig = null, For = null) {
        let color = Cons.HeaderColor;
        if (rowConfig) {
            color = Cons.ColorLevels[rowConfig.code.split('.').length];
        }
        color = color.replace("#", "");
        color = color.toUpperCase();
        if ((rowConfig && !rowConfig.hasChild) || For == 2) {
            color = "FFFFFF"
        }
        if (For !== 1) {
            cell.border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
            };
            cell.fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:color},
            };
        }
        if (!rowConfig) {
            cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            cell.font = {'bold': true};
        }
    },

    setCellData(sheet, colConfig, rowConfig, data, exportOption) {
        const cell = sheet.getCell(colConfig.excelCol + rowConfig.excelRow);
        const expand = exportOption.expands ? 2 : true;
        this.setStyle(cell, rowConfig, expand);
        if(exportOption.expands) {
            cell.value = data[colConfig.key + " " + rowConfig.key];
            if (colConfig.func && colConfig.func.startsWith('p')) {
                cell.numFmt = Cons.PERCENT_FORMAT;
            }
            return;
        }
        if (rowConfig.hasChild) {
            /*
            if (colConfig.cType === Cons.C_TYPE[0]) {
                cell.value = data[colConfig.key + " " + rowConfig.key];
                return;
            }
            let childs = TreeUtils.findAllChild(this.rowConfArray, rowConfig.code);
            let formula = [];
            for (let child of childs) {
                formula.push(colConfig.excelCol + child.excelRow);
            }
            formula = formula.join("+");
            cell.value = { formula };
            */
            if (colConfig.cType === Cons.C_TYPE[0]) {
                cell.value = data[colConfig.key + " " + rowConfig.key];
                return;
            }
           this.setCellSumof(cell, colConfig, rowConfig);
        }
        if (colConfig.func && colConfig.func.startsWith('p')) {
            this.setColFunc(cell, colConfig, rowConfig, data);
        }
        if (!cell.value && colConfig.func) {
            this.setColFunc(cell, colConfig, rowConfig, data);
        }
        if (!cell.value) {
            cell.value = data[colConfig.key + " " + rowConfig.key];
        }
    },

    setCellSumof(cell, colConfig, rowConfig, childs = null) {
        if (!childs) {
            childs = TreeUtils.findAllChild(this.rowConfArray, rowConfig.code);
        }
        let formula = [];
        for (let child of childs) {
            formula.push(colConfig.excelCol + child.excelRow);
        }
        formula = formula.join("+");
        cell.value = { formula };
    },

    setColFunc(cell, colConfig, rowConf, data) {
        let isPercent = colConfig.func.includes("p");
        const relateColums = this.colConfArray.filter(q => colConfig.func.includes("{" + q.code + "}"));
        let formula = colConfig.func + "";
        if (isPercent) {
            if (!rowConf.key) return;
            formula = colConfig.func.replace("p", "");
            cell.numFmt = Cons.PERCENT_FORMAT;
        }
        for (let column of relateColums) {
            let cellName = column.excelCol + rowConf.excelRow;
            formula = formula.replace("{" + column.code + "}", cellName);
        }
        cell.value = { formula };
    },
}

export default excelBuilder;