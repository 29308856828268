import React, { Component } from 'react';
import Box from '@mui/material/Box';
const danhMuc = [
    {
        name : "Thông tin báo cáo",
        url: "/"
    },
    {
        name: "Import điểm danh",
        url: "/import"
    },
    {
        name: "Import Kết quả",
        url: "/importkq"
    },
    {
        name: "Cấu trúc phòng ban",
        url: "/cautruc"
    }
]

class NavBar extends Component {

    state = {
    }

    render() {
        return (
            <Box sx={{ width: '100%', typography: 'body1', borderBottom: 1, borderColor: 'divider' }} style={{padding: "10px"}}>
                {
                    danhMuc.map(q => <a href={q.url} style={{marginInlineEnd: '10px'}}>{q.name}</a>)
                }
            </Box>
        )
    }
}
 
export default NavBar;